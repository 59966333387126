









import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { VProgressLinear } from "vuetify/lib";

@Component({
  components: { VProgressLinear },
})
export default class AProgressBar extends Vue {
  @Prop({
    default: 0,
    type: Number,
    validator: v => v >= 0 && v <= 100,
  })
  readonly value!: number;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly thin!: boolean;

  @Prop({
    default: "#1976d2",
    type: String,
  })
  readonly color!: string;
}
