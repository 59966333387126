
























































// AAuditItemListManipulationControl
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import AContentLanguageSwitch from "@/components/controls/AContentLanguageSwitch.vue";
import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";

import { api } from "@/store/modules/audit";
import { CurrentAuditState } from "@/store/modules/audit/types";
import { debounce } from "lodash";
import { DIALOG_NAMES } from "@/routenames";
import { Aggregation, Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { extractQueries } from "@auditcloud/shared/lib/utils/filter/FilterUtils";

const auditModul = namespace(api.namespace);

@Component({
  components: { ASquareIconBtn, AContentLanguageSwitch },
})
export default class AAuditItemsListFilter extends Vue {
  @auditModul.State((state: CurrentAuditState) => state.fulltextSearch)
  xGetFulltextSearch!: string;

  @auditModul.Mutation(api.mutations.SET_FULLTEXT_SEARCH)
  setFulltextSearch!: (text: string) => void;

  @auditModul.Getter(api.getters.getManipulatorStatus)
  manipulatorStatus!: {
    filters: number;
    sortings: number;
    grouping: boolean;
  };

  @Getter(api.getters.getAuditItemListFilterAggregations, {
    namespace: api.namespace,
  })
  aggregations!: Aggregation[];

  get filterCount(): null | number {
    return this.manipulatorStatus.filters > 0
      ? this.manipulatorStatus.filters
      : null;
  }

  get sortCount(): null | number {
    return this.manipulatorStatus.sortings > 0
      ? this.manipulatorStatus.sortings
      : null;
  }

  mounted() {
    this.setFulltextSearch("");
  }

  @auditModul.Mutation(api.mutations.SET_FILTER_SETTING)
  setFilter!: (v: Filter) => void;

  @auditModul.Mutation(api.mutations.CLEAR_FILTER_SETTINGS)
  clearFilters!: () => void;

  setFulltextSearchDebounced = debounce((val: string | null) => {
    if (val === null) {
      val = "";
    }
    const { filters, fullText } = extractQueries(val, this.aggregations);
    this.setFulltextSearch(fullText);
    if (filters.length) {
      this.clearFilters();
      filters.forEach(f => this.setFilter(f));
    }
  }, 250);

  showSort(evt: any) {
    const route = {
      ...this.$route,
      name: this.$route.name ?? "error",
      params: {
        dialogName: DIALOG_NAMES.SORT,
      },
    };
    this.$router.push(route);
  }

  showFilter(evt: any) {
    const route = {
      ...this.$route,
      name: this.$route.name ?? "error",
      params: {
        dialogName: DIALOG_NAMES.FILTER,
      },
    };
    this.$router.push(route);
  }
}
