var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-editor-item",class:{ 'min-height-editor': !!_vm.forViolation }},[_c('div',{staticClass:"background-container"},[(_vm.createButton.tooltip && _vm.editorHidden)?_c('v-tooltip',{staticStyle:{"width":"100%"},attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-4 float-right",staticStyle:{"z-index":"2"},attrs:{"color":_vm.createButton.tooltip.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.createButton.tooltip.icon)+" ")])]}}],null,false,1919048884)},[_c('span',[_vm._v(" "+_vm._s(_vm.createButton.tooltip.text)+" ")])]):_vm._e()],1),(_vm.openProcessAfterCreation)?_c('div',{staticClass:"d-flex justify-center align-center fill-height"},_vm._l((_vm.relevantMeasureTypes),function(measureType,idx){return _c('v-btn',{key:idx,staticClass:"mx-1 my-2",attrs:{"disabled":!_vm.measureTitleLengthValid ||
        (_vm.runningCreateMeasureType !== null &&
          _vm.runningCreateMeasureType !== measureType.id) ||
        !_vm.isMeasureCreationAllowed,"loading":_vm.runningCreateMeasureType === measureType.id,"color":measureType.color,"title":_vm.$ft(measureType.text)},on:{"click":function($event){return _vm.runTransition(measureType.id)}}},[_vm._v(" "+_vm._s(_vm.$ft(measureType.short))+" ")])}),1):(_vm.editorHidden)?[_c('v-btn',{staticClass:"create-btn",staticStyle:{"width":"100%"},attrs:{"text":"","tile":"","color":"accent","disabled":!_vm.isMeasureCreationAllowed},on:{"click":function($event){return _vm.openForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.createButton.measureAddLabel))])],1)]:[_c('ATableItem',{attrs:{"nopadding":"","noheaderpadding":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(_vm.createButton.measureAddLabel)+" ")])]},proxy:true},{key:"header-right",fn:function(){return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.runningCreateMeasureType !== null},on:{"click":_vm.closeForm}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]},proxy:true}])},[[_c('div',{staticStyle:{"position":"relative","height":"100%"}},[_c('v-textarea',{attrs:{"solo":"","flat":"","autofocus":"","auto-grow":"","counter":_vm.measureMaxCharLimit,"counter-value":function (v) { return v.trim().length; },"rows":2,"placeholder":_vm.enableMeasureImplementationInQuickEditor
                ? _vm.$t(
                    'components.dialogs.audit_measure_dialog.measure_placeholder_short'
                  )
                : _vm.$t(
                    'components.dialogs.audit_measure_dialog.measure_placeholder'
                  )},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(_vm.enableMeasureImplementationInQuickEditor)?[_c('v-divider'),_c('v-textarea',{attrs:{"solo":"","flat":"","auto-grow":"","hide-details":"","rows":3,"placeholder":_vm.$t(
                  'components.dialogs.audit_measure_dialog.measure_placeholder_long'
                )},model:{value:(_vm.measureImplementation),callback:function ($$v) {_vm.measureImplementation=$$v},expression:"measureImplementation"}})]:_vm._e(),_c('v-card-actions',[_c('v-spacer'),_vm._l((_vm.relevantMeasureTypes),function(measureType,idx){return _c('v-btn',{key:idx,attrs:{"disabled":!_vm.measureTitleLengthValid ||
                !_vm.isMeasureCreationAllowed ||
                (_vm.runningCreateMeasureType !== null &&
                  _vm.runningCreateMeasureType !== measureType.t),"loading":_vm.runningCreateMeasureType === measureType.t,"color":measureType.color,"title":_vm.$ft(measureType.text)},on:{"click":function($event){return _vm.runTransition(measureType.id)}}},[_vm._v(" "+_vm._s(_vm.$ft(measureType.short))+" ")])})],2),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.runningCreateMeasureType !== null}},[_c('v-progress-circular',{attrs:{"indeterminate":true}})],1)],2)]],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }