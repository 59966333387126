




















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { VBtn } from "vuetify/lib";
import { ValuedItemType } from "@auditcloud/shared/lib/types/ItemTypes";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { pick } from "lodash";

@Component({
  components: { VBtn },
})
export default class AQuickActionButtons extends Vue {
  loadingId: nullable<string> = null;
  get additionalLoading() {
    return this.additionalItems.findIndex(i => i.id === this.loadingId) > -1;
  }

  get selectedItem() {
    const item = this.additionalItems.find(i => i.id === this.activeId);
    console.log("selectedItem", item, this.activeId);
    if (item) {
      return item;
    } else if (
      this.additionalItems.length > 0 &&
      !this.disableSegmentedSelection
    ) {
      return this.additionalItems[0];
    } else {
      return undefined;
    }
  }

  get activeItem() {
    if (this.selectedItem) {
      return this.selectedItem.id;
    } else {
      return undefined;
    }
  }

  get activeColor() {
    if (this.selectedItem) {
      return this.selectedItem.color;
    } else if (this.additionalItems[0]) {
      return this.additionalItems[0].color;
    } else {
      return undefined;
    }
  }

  get additionalItems() {
    const self = this;
    const clickhandler = (id: string) => {
      return () => {
        self.$emit("activate", id);
      };
    };

    console.log("additionalItems", this.items);
    return this.items
      .filter(v => !this.directEvt.includes(v.id))
      .map(v => {
        return {
          id: v.id,
          text: this.$ft(this.$vuetify.breakpoint.lgAndUp ? v.text : v.short),
          color: v.color,
          callback: clickhandler(v.id),
        };
      });
  }

  get directItems() {
    const result: ValuedItemType[] = [];
    this.directEvt.forEach(evt => {
      const foundItem = this.items.find(item => item.id === evt);
      if (foundItem) {
        result.push(foundItem);
      }
    });
    console.log("directItems", result, this.directEvt);
    return result;
  }

  @Prop({
    default: () => [],
    type: Array,
    required: true,
  })
  readonly items!: ValuedItemType[];

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly directEvt!: string[];

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly loading!: boolean;

  @Prop({
    default: null,
    type: String,
  })
  readonly activeId!: nullable<string>;

  @Prop({
    default: true,
    type: Boolean,
  })
  readonly disableSegmentedSelection!: boolean;

  changed(itemId: string) {
    this.loadingId = itemId;
    this.$emit("activate", itemId);
  }
}
