


















































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import { api } from "@/store/modules/audit";
import {
  api as measureWorkflowsApi,
  Actions as MeasureWorkflowsActions,
} from "@/store/modules/measureWorkflows";
import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";
import {
  dialogRoute,
  DIALOG_ACTIONS,
  DIALOG_NAMES,
  ROUTE_NAMES,
} from "@/routenames";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { MeasurePolicyViolation } from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { MEASURE_MAX_CHAR_LIMIT } from "@auditcloud/shared/lib/workflow/configs/constants";

@Component({
  components: { ATableItem },
})
export default class AMeasureCreateEditor extends Vue {
  text: string = "";
  runningCreateMeasureType: string | null = null;
  measureImplementation: string = "";
  editorHidden: boolean = true;

  @Getter(api.getters.getMeasureWorkflowId, { namespace: api.namespace })
  measureWorkFlowId!: string;

  @Getter(api.getters.getMeasureCreationAllowed, { namespace: api.namespace })
  isMeasureCreationAllowed!: string;

  @Getter(api.getters.getOpenProcessAfterCreation, {
    namespace: api.namespace,
  })
  openProcessAfterCreation!: boolean;

  @Getter(api.getters.getAuditClass, {
    namespace: api.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Action(measureWorkflowsApi.actions.executeCreateTransition, {
    namespace: measureWorkflowsApi.namespace,
  })
  executeCreateTransition!: MeasureWorkflowsActions["executeCreateTransition"];

  @Prop({
    type: Object,
    required: true,
    validator: v => {
      return (
        v instanceof Object &&
        typeof v.auditId === "string" &&
        typeof v.auditItemId === "string" &&
        typeof v.findingId === "string"
      );
    },
  })
  context!: {
    auditId: string;
    auditItemId: string;
    findingId: string;
  };

  @Prop({
    type: Array,
    default: () => [],
  })
  measureTypes!: idable<MeasureType>[];

  @Prop({
    type: Object,
    default: null,
  })
  forViolation!: MeasurePolicyViolation | null;

  get createButton() {
    const measureType = this.violationMeasureType;
    const violatoin = this.forViolation;
    if (violatoin === null || measureType === null) {
      return {
        tooltip: null,
        measureAddLabel: this.$t(
          "components.widgets.audit_overview.measure_add"
        ).toString(),
      };
    }

    const violationLevel = violatoin.violationLevel;

    const isStrict = violationLevel === "strict";
    return {
      tooltip: {
        icon: isStrict ? "mdi-alert" : "mdi-information",
        color: isStrict ? "red" : "orange",
        text: this.$t(
          `components.widgets.measure_create_editor.warning_measure_policy_${violationLevel}`
        ).toString(),
      },
      measureAddLabel: this.$t(
        `components.widgets.audit_overview.measure_add_specific_${violationLevel}`,
        {
          measureType: this.$ft(measureType.text),
        }
      ).toString(),
    };
  }

  get violationMeasureType() {
    return (
      this.measureTypes.find(v => v.id === this.forViolation?.measureTypeId) ??
      null
    );
  }

  get enableMeasureImplementationInQuickEditor() {
    return this.auditClass?.enableMeasureImplementationInQuickEditor;
  }

  get measureMaxCharLimit() {
    return MEASURE_MAX_CHAR_LIMIT;
  }

  get measureTitleLengthValid() {
    return this.text.length <= this.measureMaxCharLimit;
  }

  get relevantMeasureTypes() {
    // if the editor is the editor for a violation, only allow the measure type of the violation
    const violation = this.forViolation;
    return violation
      ? this.measureTypes.filter(v => v.id === violation.measureTypeId)
      : this.measureTypes;
  }

  runTransition(measureTypeId: string) {
    const workflowId = this.measureWorkFlowId;
    if (workflowId) {
      this.runningCreateMeasureType = measureTypeId;

      this.executeCreateTransition({
        ...this.context,
        content: {
          text: this.text.trim(),
          measureImplementation: this.measureImplementation.trim(),
          measureTypeId,
        },
      })
        .then(res => {
          console.log("executeCreateTransition done", res);
          this.text = "";
          this.measureImplementation = "";

          if (this.openProcessAfterCreation) {
            const newRoute = {
              name: dialogRoute(
                this.$route.name ?? ROUTE_NAMES.HOME,
                DIALOG_NAMES.MEASURE
              ),
              params: {
                ...this.$route.params,
                measureId: res.data.id,
                action: DIALOG_ACTIONS.EDIT,
              },
            };
            this.$router.push(newRoute);
          }
          this.closeForm();
        })
        .catch(err => {
          console.error("executeCreateTransition failed", err);
        })
        .finally(() => {
          this.runningCreateMeasureType = null;
        });
    }
  }

  closeForm() {
    this.editorHidden = true;
  }
  openForm() {
    this.editorHidden = false;
  }
}
