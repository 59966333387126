var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-editor-item"},[_c('ATableItem',{attrs:{"highlight-color":_vm.color,"indent":_vm.indent},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticStyle:{"line-height":"1.25rem"}},[_vm._v(_vm._s(_vm.type))]),_c('span',{staticClass:"ml-2 text-body-2 grey--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.measureId)+" ")])])]},proxy:true},{key:"header-right",fn:function(){return [_c('div',{staticClass:"no-new-line"},[(_vm.permissionDelete && !_vm.readonly)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.edit}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)]},proxy:true}])},[_c('p',{staticClass:"pre",class:{ 'text--secondary': _vm.measureText.trim().length === 0 }},[(
          _vm.measureSuggestion.trim().length > 0 &&
          _vm.measureText.trim().length === 0
        )?_c('AMeasureProcessTitleSuggestion',{attrs:{"suggestion":_vm.measureSuggestion}}):[_c('div',{domProps:{"textContent":_vm._s(
            _vm.measureText.trim().length === 0
              ? _vm.$t(
                  'components.dialogs.audit_measure_dialog.measure_created_default'
                )
              : _vm.measureText
          )}}),(
            _vm.enableMeasureImplementationInQuickEditor &&
            _vm.measureImplementation.trim().length !== 0
          )?[_c('v-divider',{staticClass:"my-4"}),_c('div',{domProps:{"textContent":_vm._s(_vm.measureImplementation)}})]:_vm._e()]],2)]),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }