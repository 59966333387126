


































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Dictionary } from "lodash";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import AStandardChip from "@/components/snippets/AStandardChip.vue";
import AAuditOverviewQuestion from "@/components/widgets/AAuditOverviewQuestion.vue";
import AAuditOverviewFinding from "@/components/widgets/AAuditOverviewFinding.vue";
import AAuditOverviewMeasure from "@/components/widgets/AAuditOverviewMeasure.vue";
import AFindingCreateEditor from "@/components/widgets/AFindingCreateEditor.vue";
import AMeasuresCreationWidget from "@/components/widgets/AMeasuresCreationWidget.vue";
import ALinkFindingToRequirementWidget from "@/components/widgets/ALinkFindingToRequirementWidget.vue";
import PaginationControl from "@auditcloud/components/controls/PaginationControl.vue";
import AAddAuditItemDialog from "@/components/dialogs/AAddAuditItemDialog.vue";
import {
  Finding,
  MeasurePolicyPerFindingType,
  SelfAssessment,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { FindingTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import { api as confApi } from "@/store/modules/configuration";
import { api } from "@/store/modules/audit";
import { api as measuresApi } from "@/store/modules/measures";
import {
  DimensionMap,
  AuditItemSettings,
  AnswerStatus,
  AuditItemProperties,
} from "@/store/modules/audit/types";
import {
  nullable,
  idable,
  StrictDictionary,
} from "@auditcloud/shared/lib/types/common";

import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { AuditItemsInConflictState } from "@/store/modules/audit/getters";
import * as IDS from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";

import { QuestionSearchResultMap } from "@auditcloud/shared/lib/types/Audit/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { dialogRoute, DIALOG_NAMES, ROUTE_NAMES } from "@/routenames";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";

const confModul = namespace(confApi.namespace);
const auditModule = namespace(api.namespace);
const measuresModul = namespace(measuresApi.namespace);

@Component({
  components: {
    ATableItem,
    AStandardChip,
    AAuditOverviewQuestion,
    AAuditOverviewFinding,
    AAuditOverviewMeasure,
    AFindingCreateEditor,
    PaginationControl,
    AMeasuresCreationWidget,
    ALinkFindingToRequirementWidget,
    AAddAuditItemDialog,
  },
})
export default class AAuditOverview extends Vue {
  readonly allowCompletion = false;
  readonly cols: number = 12;
  transitions:
    | null
    | {
        id?: string;
        name: TranslateableText;
        description: TranslateableText;
      }[] = null;

  uploadErrorsForFindings: Dictionary<boolean> = {};
  linkFindingDialog: boolean = false;

  @Watch("linkFindingDialog")
  onLinkFindingDialogChange(isOpening) {
    if (!isOpening) {
      this.cancelLinkFinding();
    }
  }

  @measuresModul.Getter(measuresApi.getters.getMappedFindingId2MeasureIds)
  findingMeasuresMap!: { [findingId: string]: string[] };

  @auditModule.Getter(api.getters.getAuditItems)
  auditItemsForSearch!: Array<AuditItemWithId>;

  @auditModule.Getter(api.getters.getAuditItemsForList)
  auditItems!: Array<AuditItemWithId>;

  get auditItemsMap(): Dictionary<AuditItemWithId> {
    return this.auditItemsForSearch.reduce((acc, auditItem) => {
      return { ...acc, [auditItem.id]: auditItem };
    }, {});
  }

  @auditModule.Getter(api.getters.getFindingsMap)
  findings!: Dictionary<Finding>;

  @auditModule.Getter(api.getters.getAuditItemId2FindingsMap)
  auditItemId2FindingsMap!: Dictionary<idable<Finding>>;

  @auditModule.Getter(api.getters.getAuditItemId2SelfAssessmentMap)
  auditItemId2SelfassessmentsMap!: StrictDictionary<idable<SelfAssessment>[]>;

  @auditModule.Getter(api.getters.getAuditItemId2Setting)
  auditItemId2Setting!: AuditItemSettings;

  @auditModule.Getter(api.getters.getPageCount)
  pageCount!: number;

  @auditModule.Getter(api.getters.getCurrentPage)
  currentPage!: number;

  @auditModule.Getter(api.getters.getPageSizes)
  pageSizes!: Array<number | null>;

  @auditModule.Mutation(api.mutations.SET_PAGING_CONFIG)
  mutatePagination!: (payload: {
    page: number;
    pageSize: number | null;
  }) => void;

  paginationChanged(payload: { page: number; pageSize: number | null }) {
    window.scrollTo(0, 0);
    this.mutatePagination(payload);
  }

  @auditModule.Getter(api.getters.getAuditId)
  auditId!: string | null;

  @auditModule.Getter(api.getters.getAuditName)
  auditName!: string | null;

  @auditModule.Getter(api.getters.getAuditProgramId)
  auditProgramId!: string | null;

  @auditModule.Action(api.actions.moveFinding)
  moveFinding!: (payload: {
    findingId: string;
    formAuditItemId: string | null;
    toAuditItemId: string | null;
  }) => Promise<boolean>;

  @auditModule.Getter(api.getters.getMappedFindingTypes)
  mappedFindingTypes!: FindingTypeMap;

  @auditModule.Getter(api.getters.getUnassignedFindings)
  unassignedFindings!: idable<Finding>[];

  @auditModule.Getter(api.getters.getMeasureWorkflow)
  measureWorkflow!: null | MeasureWorkflow;

  @auditModule.Getter(api.getters.getAuditDimensionsMap)
  auditDimensionsMap!: DimensionMap;

  @auditModule.Getter(api.getters.getAuditItemDimensionAnswerStatusMap)
  auditItemDimensionAnswerStatusMap!: { [auditItemId: string]: AnswerStatus[] };

  @auditModule.Getter(api.getters.getSelfAssessmentCompleted)
  selfAssessmentCompleted!: boolean;

  @auditModule.Getter(api.getters.getAuditItemProperties)
  auditItemPropertiesMap!: Map<AuditItemWithId["id"], AuditItemProperties>;
  showSelfAssessmentBadge(auditItemId: string): boolean {
    return (
      this.selfAssessmentIncluded &&
      this.auditItemPropertiesMap.get(auditItemId)?.isSelfAssessmentEnabled ===
        true
    );
  }

  @auditModule.Getter(api.getters.getSelfAssessmentIncluded)
  selfAssessmentIncluded!: boolean;

  @auditModule.Getter(api.getters.getAuditPermissions)
  permissions!: AuditPermissions;

  @auditModule.Getter(api.getters.getAuditStatus)
  auditStatus!: string | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly stickyHeader!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly measuresEditable!: boolean;

  @Prop({
    type: Number,
    default: 3,
  })
  readonly columnCount!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disableMeasurePolicyPreviews!: boolean;

  addQuestionDialog: boolean = false;

  @auditModule.Getter(api.getters.getSearchResultsByAuditItemIds)
  searchResultsByAuditItemIds!: QuestionSearchResultMap;

  @auditModule.Getter(api.getters.getUnlinkedFindingsEnabled)
  unlinkedFindingsEnabled!: boolean;

  @auditModule.Getter(api.getters.getUnlinkedFindingsAllowed)
  unlinkedFindingsAllowed!: boolean;

  @auditModule.Getter(api.getters.getAddRequirementsAllowed)
  addRequirementsAllowed!: boolean;

  @auditModule.Getter(api.getters.getAddFreeFindingsAllowed)
  addFreeFindingsAllowed!: boolean;

  @auditModule.Getter(api.getters.getAuditItemsInConflictState)
  auditItemsInConflictState!: AuditItemsInConflictState;

  @confModul.Getter(confApi.getters.selfAssessmentFeatureEnabled)
  selfAssessmentFeatureEnabled!: boolean;

  @auditModule.Action(api.actions.deleteAllUnassignedFindings)
  deleteAllUnassignedFindings!: () => Promise<void>;

  @auditModule.Mutation(api.mutations.TOGGLE_FILTER_SETTING)
  toggleFilterSetting!: (payload: Filter) => void;

  movingFindingId: nullable<string> = null;
  movingAudititemId: nullable<string> = null;
  toAuditItemId: nullable<string> = null;

  @auditModule.Getter(api.getters.getMeasurePoliciesStatus)
  measurePoliciesStatus!: null | {
    type: MeasurePolicyPerFindingType["policyType"];
    level: MeasurePolicyPerFindingType["policies"][string][string];
  };

  get hasRequiredMeasures() {
    return (
      this.writePermission &&
      (this.auditStatus === AuditStatusId.Wrapup ||
        this.auditStatus === AuditStatusId.Reporting) &&
      this.measurePoliciesStatus?.level === "strict"
    );
  }

  openMeasuresCreateDialog() {
    console.log("OPEN Create Dialog", this.measurePoliciesStatus);
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;
    if (auditId) {
      this.$router.push({
        name: dialogRoute(routeName, DIALOG_NAMES.MEASURES_CREATE_DIALOG),
        params: {
          auditId,
        },
      });
    }
  }

  get secColumnCount() {
    return [1, 2, 3].includes(this.columnCount) ? this.columnCount : 3;
  }

  get headers() {
    const header = [
      this.$t("components.widgets.audit_overview.questions"),
      this.$t("components.widgets.audit_overview.findings"),
      this.$t("components.widgets.audit_overview.measures"),
    ];
    const { lgAndUp, mdAndUp } = this.$vuetify.breakpoint;
    console.log("columnCount", this.secColumnCount, "lg/md", lgAndUp, mdAndUp);
    if (lgAndUp || (mdAndUp && this.secColumnCount <= 2)) {
      return header.slice(0, this.secColumnCount);
    } else {
      return [this.$t("components.widgets.audit_overview.checklist")];
    }
  }

  get colSpanMdSub() {
    if (this.headers.length - 1 > 0) {
      return this.cols / (this.headers.length - 1);
    } else {
      return 0;
    }
  }
  get colSpanMd() {
    return this.headers.length !== 2
      ? this.cols
      : this.cols / this.headers.length;
  }

  get colSpanLgSub() {
    if (this.secColumnCount - 1 > 0) {
      return this.cols / (this.secColumnCount - 1);
    } else {
      return 0;
    }
  }
  get colSpanLg() {
    return this.cols / this.secColumnCount;
  }

  get writePermission() {
    return this.permissions.write;
  }

  markCompleted(auditItem: any) {
    console.log(auditItem);
  }

  setMoveFinding(findingId: string, auditItemId: string | null) {
    this.movingFindingId = findingId;
    this.movingAudititemId = auditItemId;
    this.linkFindingDialog = true;
  }

  filterConflicts() {
    console.log("filterConflicts");
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_ANSWERD,
      value: "has_conflicts",
    });
  }

  linkFinding() {
    if (this.movingFindingId) {
      this.moveFinding({
        findingId: this.movingFindingId,
        formAuditItemId: this.movingAudititemId,
        toAuditItemId: this.toAuditItemId,
      })
        .then(res => {
          console.log(res ? "Move done" : "Move canceled");
          this.cancelLinkFinding();
        })
        .catch(err => {
          console.error("Move failed", err);
        });
    }
  }

  cancelLinkFinding() {
    this.linkFindingDialog = false;
    this.movingAudititemId = null;
    this.movingFindingId = null;
    this.toAuditItemId = null;
  }

  @Watch("measureWorkflow", { immediate: true, deep: true })
  async onMeasureWorkflowChanged(measureWorkflow: null | MeasureWorkflow) {
    this.transitions = null;

    console.log("measureWorkflow", measureWorkflow);
    if (measureWorkflow) {
      this.transitions = measureWorkflow
        .possibleTransitions(
          {
            id: this.$user.id(),
            customClaims: {
              roles: this.$user.roles(),
            },
          },
          null
        )
        .map(v => {
          return {
            id: v.id,
            name: v.name,
            description: v.description,
          };
        });
    }
  }

  deleteUnlinkedFindings() {
    if (
      confirm(
        this.$ft(
          "components.widgets.audit_overview.comfirm_delete_unlinked_findings"
        )
      )
    ) {
      this.deleteAllUnassignedFindings();
    }
  }

  updateFindingErrorStatus(findingId: string, state: boolean) {
    this.uploadErrorsForFindings[findingId] = state;
  }

  addFinding() {
    if (this.auditId) {
      const newRoute = {
        name: dialogRoute(
          this.$route.name ?? ROUTE_NAMES.AUDITEXECUTION,
          DIALOG_NAMES.FINDING
        ),
        params: {
          auditId: this.auditId,
        },
      };
      this.$router.push(newRoute);
    }
  }
}
