
























































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter, Action, Mutation } from "vuex-class";

import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import ASelfAssessmentResultExpansion from "@/components/widgets/ASelfAssessmentResultExpansion.vue";
import ASelfAssessmentResultPreview from "@/components/widgets/ASelfAssessmentResultPreview.vue";
import AQuickActionButtons from "@/components/controls/AQuickActionButtons.vue";
import AAttachmentButton from "@/components/controls/AAttachmentButton.vue";
import AAttachmentUploadList from "@/components/snippets/AAttachmentUploadList.vue";
import { Attachment, QuickActionConfig } from "../types";
import { VBtn, VIcon, VTextarea } from "vuetify/lib";
import { api as auditApi } from "../../store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";
import {
  Finding,
  SelfAssessment,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { attachmentHandlers } from "@/components/types";
import {
  api as findingApi,
  Actions as FindingActions,
  Mutations as FindingMutations,
} from "@/store/modules/finding";
import { dialogRoute, DIALOG_NAMES } from "@/routenames";
import { RawLocation, Route } from "vue-router";
import { NULL_UUID } from "@auditcloud/shared/lib/constants";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import {
  HIGH_RISK_ID,
  LOW_RISK_ID,
  MEDIUM_RISK_ID,
  RISK_TYPE_ID,
} from "@auditcloud/shared/lib/types/UrgencyChart";
import { emptyFinding } from "@/store/modules/finding/utils";
import { typeIsFinding } from "@auditcloud/shared/lib/schemas/type-guards";

@Component({
  components: {
    ATableItem,
    ASelfAssessmentResultExpansion,
    ASelfAssessmentResultPreview,
    AQuickActionButtons,
    AAttachmentButton,
    AAttachmentUploadList,
    VBtn,
    VIcon,
    VTextarea,
  },
})
export default class AFindingCreateEditor extends Vue {
  isRiskType(typeId) {
    return typeId === RISK_TYPE_ID;
  }

  get useRiskBasedFindings(): boolean {
    return this.auditClass?.useRiskBasedFindings ?? false;
  }
  get quickEditorTargetProperty(): string {
    return this.auditClass?.quickEditorTargetProperty ?? "text";
  }
  get riskItems() {
    return [
      ...this.quickActionConfig.actions.filter(
        findingType =>
          ![LOW_RISK_ID, MEDIUM_RISK_ID, HIGH_RISK_ID].includes(findingType.id)
      ),
      {
        id: RISK_TYPE_ID,
        text: { de: "Feststellung", en: "Finding" },
        short: { de: "Feststellung", en: "Finding" },
        is_quick_action: true,
        color: "red lighten-2",
      },
    ];
  }
  get riskItemsDirect() {
    return [...this.quickActionConfig.directActions, RISK_TYPE_ID];
  }

  loading: boolean = false;
  xEditorDisplay: boolean = false;

  dimensions: string[] | null = null;
  text: string = "";
  attachments: Attachment[] = [];
  evaluationForSelfAssessmentWithId: string | null = null;

  @Action(findingApi.actions.createFinding, {
    namespace: findingApi.namespace,
  })
  createFinding!: FindingActions["createFinding"];

  @Mutation(findingApi.mutations.INIT_FROM_QUICKEDITOR, {
    namespace: findingApi.namespace,
  })
  initFullEditor!: FindingMutations["INIT_FROM_QUICKEDITOR"];

  @Getter(auditApi.getters.getAuditDimensionsMap, {
    namespace: auditApi.namespace,
  })
  auditDimensionsMap!: {
    [dimensionId: string]: { id: string; name: TranslateableText } | undefined;
  };

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Getter(auditApi.getters.getFindingQuickActionConfig, {
    namespace: auditApi.namespace,
  })
  quickActionConfig!: QuickActionConfig;

  @Getter(auditApi.getters.getForceFullFindingEditor, {
    namespace: auditApi.namespace,
  })
  forceFullFindingEditor!: boolean;

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  readonly attachmentHandlers = attachmentHandlers;

  clear() {
    this.text = "";
    this.dimensions = null;
    this.attachments = [];
  }

  get selfAssessmentSelectedForEvaluation() {
    return this.selfAssessmentResults.find(
      result => result.id === this.evaluationForSelfAssessmentWithId
    );
  }

  get editorDisplay() {
    return this.xEditorDisplay;
  }

  set editorDisplay(val: boolean) {
    if (this.forceFullFindingEditor || this.$vuetify.breakpoint.mobile) {
      this.openFullEditor();
    } else {
      this.xEditorDisplay = val;
    }
  }

  get editorHidden(): boolean {
    return this.editorDisplay === false;
  }

  get trigger() {
    return async (
      dimensions: string[] | null,
      evaluationForSelfAssessmentWithId: string | null
    ) => {
      this.clear();

      console.log("Trigger:click", dimensions, this.editorDisplay);
      this.dimensions = dimensions;
      this.editorDisplay = true;
      this.evaluationForSelfAssessmentWithId =
        evaluationForSelfAssessmentWithId;
    };
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  questionInSelfAssessment!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  ongoingSelfAssessment!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  selfAssessmentResults!: idable<SelfAssessment>[];

  @Prop({
    type: [String, Object],
    default: null,
  })
  selfAssessmentQuestionText!: TranslateableText | null;

  @Prop({
    type: String,
    required: true,
  })
  auditItemId!: string;

  fullEditor(route: Route): RawLocation {
    return {
      name: dialogRoute(route.name ?? "error", DIALOG_NAMES.FINDING),
      params: {
        ...route.params,
        auditItemId: this.auditItemId,
        findingId: NULL_UUID,
        measureId: "-",
      },
    };
  }

  openFullEditor() {
    const newRoute = this.fullEditor(this.$route);

    this.initFullEditor({
      targetValue: this.text,
      targetProperty: this.quickEditorTargetProperty,
      dimensions: this.dimensions,
      auditItemId: this.auditItemId,
      attachments: this.attachments,
      evaluationForSelfAssessmentWithId: this.evaluationForSelfAssessmentWithId,
    });
    this.$router.push(newRoute);
    this.xEditorDisplay = false;
  }

  addAttachment(attachments: Attachment[]) {
    this.attachments.push(...attachments);
  }

  removeAttachment(index: number) {
    this.attachments = this.attachments.filter((_, i) => i !== index);
  }

  async onSave(type: string) {
    this.loading = true;

    const finding: Finding = emptyFinding({
      auditItemId: this.auditItemId,
      dimensions: this.dimensions,
    });
    finding.type = type;
    finding.selfAssessmentId = this.evaluationForSelfAssessmentWithId;
    finding[this.quickEditorTargetProperty] = this.text;

    if (!typeIsFinding(finding)) {
      throw new Error(
        `The configured quickEditorTargetProperty "${this.quickEditorTargetProperty}" is not a valid Finding property`
      );
    }

    this.createFinding({
      attachments: this.attachments,
      finding,
    }).then(result => {
      if (result.isOk()) {
        const findingId = result.value;
        console.log("Store new finding", findingId);
        this.$emit("upload-error-status", { findingId, state: false });
      } else {
        if (result.error.errorType === "UPLOAD_ERROR") {
          const findingId = result.error.findingId;
          this.removeAllAttachments();
          this.$emit("upload-error-status", { findingId, state: true });
        }
      }
      this.editorDisplay = false;
      this.clear();
      this.loading = false;
    });
  }
  removeAllAttachments() {
    this.attachments.forEach((_, idx) => {
      this.removeAttachment(idx);
    });
  }
}
