



































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import {
  api as addAuditItemDialogApi,
  Actions,
  Getters,
  Mutations,
} from "../../store/modules/addAuditItemDialog";
import { api as auditApi } from "../../store/modules/audit";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import AAuditPreparationItem from "@/components/widgets/AAuditPreparationItem.vue";
import ASearchFilter from "@/components/widgets/ASearchFilter.vue";
import { Filter, FilterUiApi } from "@auditcloud/shared/lib/utils/filter/types";
import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";
import AQuestionListGroups from "@/components/widgets/AQuestionListGroups.vue";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";

type Mode = "grouped" | "ungrouped";

@Component({
  components: {
    AAuditPreparationItem,
    ASearchFilter,
    ASquareIconBtn,
    AQuestionListGroups,
  },
})
export default class AAddAuditItemDialog extends Vue {
  close() {
    this.$emit("close");
  }

  mode: Mode = "grouped";
  selectedAuditItemIds: Array<AuditItemWithId["id"]> = [];

  get isAtLeastOneItemSelected() {
    return this.selectedAuditItemIds.length > 0;
  }

  get isNoAuditItemSelected() {
    return this.selectedAuditItemIds.length === 0;
  }

  get areAllAuditItemsSelected() {
    return (
      this.selectedAuditItemIds.length === this.notConsideredAuditItems.length
    );
  }

  set areAllAuditItemsSelected(value: boolean) {
    this.selectedAuditItemIds = value
      ? this.notConsideredAuditItems.map(i => i.id)
      : [];
  }

  readonly filterUIApi: FilterUiApi = {
    namespace: addAuditItemDialogApi.namespace,
    getters: {
      getAggregations:
        addAuditItemDialogApi.getters.getAuditItemListFilterAggregations,
      getFulltextSearch: addAuditItemDialogApi.getters.getFullTextSearch,
    },
    mutations: {
      TOGGLE_FILTER: addAuditItemDialogApi.mutations.TOGGLE_FILTER,
      SET_FILTERS: addAuditItemDialogApi.mutations.SET_FILTERS,
      CLEAR_FILTER_FOR_AGGREGATIONS:
        addAuditItemDialogApi.mutations.CLEAR_FILTER_FOR_AGGREGATIONS,
      CLEAR_ALL_FILTERS: addAuditItemDialogApi.mutations.CLEAR_ALL_FILTERS,
      SET_FULLTEXT_SEARCH: addAuditItemDialogApi.mutations.SET_FULLTEXT_SEARCH,
    },
  };

  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpen!: boolean;

  isAdding: boolean = false;

  @Action(addAuditItemDialogApi.actions.manuallyAddToConsideredAuditItems, {
    namespace: addAuditItemDialogApi.namespace,
  })
  manuallyAddToConsideredAuditItems!: Actions["manuallyAddToConsideredAuditItems"];

  @Mutation(addAuditItemDialogApi.mutations.TOGGLE_FILTER, {
    namespace: addAuditItemDialogApi.namespace,
  })
  toggleFilter!: Mutations["TOGGLE_FILTER"];

  @Getter(addAuditItemDialogApi.getters.getActiveFilters, {
    namespace: addAuditItemDialogApi.namespace,
  })
  activeFilters!: Filter[];

  @Getter(addAuditItemDialogApi.getters.getNotConsideredAuditItems, {
    namespace: addAuditItemDialogApi.namespace,
  })
  notConsideredAuditItems!: Getters["getNotConsideredAuditItems"];

  @Getter(addAuditItemDialogApi.getters.getFullTextSearch, {
    namespace: addAuditItemDialogApi.namespace,
  })
  filteredText!: Getters["getFullTextSearch"];

  @Getter(addAuditItemDialogApi.getters.getSearchResultsByAuditItemIds, {
    namespace: addAuditItemDialogApi.namespace,
  })
  searchResultsByAuditItemIds!: Getters["getSearchResultsByAuditItemIds"];

  @Getter(auditApi.getters.getAuditPermissions, {
    namespace: auditApi.namespace,
  })
  permissions!: AuditPermissions;

  get writePermission() {
    return this.permissions.write;
  }

  @Getter(auditApi.getters.getAreMultipleStandardsReferenced, {
    namespace: auditApi.namespace,
  })
  hasMultipleStandardIds!: boolean;

  @Getter(auditApi.getters.getIsRestrictPreparationToCategories, {
    namespace: auditApi.namespace,
  })
  isRestrictPreparationToCategories!: boolean;

  toggleAuditItemSelection(id: AuditItemWithId["id"]) {
    if (!this.selectedAuditItemIds.includes(id)) {
      this.selectedAuditItemIds.push(id);
    } else {
      this.selectedAuditItemIds.splice(
        this.selectedAuditItemIds.indexOf(id),
        1
      );
    }
  }

  addQuestions() {
    this.isAdding = true;
    this.manuallyAddToConsideredAuditItems(this.selectedAuditItemIds)
      .then(() => {
        this.selectedAuditItemIds = [];
        this.close();
      })
      .catch(err => {
        console.error("manuallyAddToConsideredAuditItems failed", err);
      })
      .finally(() => {
        this.isAdding = false;
      });
  }
}
