





















import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import AAttachmentList from "@/components/snippets/AAttachmentList.vue";
import { api } from "@/store/modules/audit";
import {
  SelfAssessment,
  StoredAttachmentEntry,
  TranslateableText,
  NotedProof,
} from "@auditcloud/shared/lib/schemas";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Dictionary, isString, pick } from "lodash";
import { namespace } from "vuex-class";
import { idable } from "@auditcloud/shared/lib/types/common";
import ANotedProofList from "@/components/snippets/ANotedProofList.vue";

const auditModule = namespace(api.namespace);

@Component({
  components: {
    ATableItem,
    AAttachmentList,
    ANotedProofList,
  },
})
export default class ASelfAssessmentResultPreview extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  selfAssessmentResult!: idable<SelfAssessment>;

  @Prop({
    type: [String, Object],
    default: null,
  })
  selfAssessmentQuestionText!: TranslateableText | null;

  @auditModule.Getter(api.getters.getResultAttachmentsMap)
  resultAttachmentsMap!: Dictionary<StoredAttachmentEntry>;

  get attachments() {
    const attachmentIds = this.selfAssessmentResult.attachmentIds;
    return pick(this.resultAttachmentsMap, attachmentIds);
  }

  get notedProofs(): NotedProof[] {
    return this.selfAssessmentResult.notedProofs ?? [];
  }
}
