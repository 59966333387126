




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AMeasureCreateEditor from "@/components/widgets/AMeasureCreateEditor.vue";
import { api } from "@/store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";

import {
  MeasurePolicyViolation,
  violationLevelSortWorstToBest,
} from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { MeasureTypeId } from "@auditcloud/shared/lib/constants";
import { lookupAndMakeIdable } from "@auditcloud/shared/lib/utils/transform/lookupAndMakeIdable";
import {
  MeasureType,
  MeasureTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";

@Component({
  components: {
    AMeasureCreateEditor,
  },
})
export default class AMeasuresCreationWidget extends Vue {
  @Prop({
    type: Object,
    required: true,
    validator: v => {
      return (
        v instanceof Object &&
        typeof v.auditId === "string" &&
        typeof v.auditItemId === "string" &&
        typeof v.findingId === "string"
      );
    },
  })
  context!: {
    auditId: string;
    auditItemId: string;
    findingId: string;
  };

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disableMeasurePolicyPreviews!: boolean;

  @Getter(configApi.getters.getMeasureTypesMapping, {
    namespace: configApi.namespace,
  })
  measureTypesMapping!: MeasureTypeMap;

  @Getter(api.getters.getMeasurePolicyViolations, {
    namespace: api.namespace,
  })
  measurePolicyViolations!: MeasurePolicyViolation[];

  @Getter(configApi.getters.getMeasureTypes, {
    namespace: configApi.namespace,
  })
  measureTypes!: MeasureType[];

  get availableMeasureTypes() {
    return this.measureTypes
      .map(v => v.id)
      .map(lookupAndMakeIdable(this.measureTypesMapping))
      .filter(typeIsNotEmpty);
  }

  get relevantMeasurePolicyViolations(): MeasurePolicyViolation[] {
    if (this.disableMeasurePolicyPreviews) {
      return [];
    }

    const findingId = this.context.findingId;
    return this.measurePolicyViolations
      .filter(violation => violation.findingIds.includes(findingId))
      .sort(
        (a, b) =>
          this.measureTypes.findIndex(v => v.id === a.measureTypeId) -
          this.measureTypes.findIndex(v => v.id === b.measureTypeId)
      );
  }

  get measureTypesWithoutPolicy() {
    return this.measureTypes.filter(
      measureType =>
        !this.relevantMeasurePolicyViolations.find(
          violation => violation.measureTypeId === measureType.id
        )
    );
  }
}
