








































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import AAttachmentList from "@/components/snippets/AAttachmentList.vue";
import AMeasureProcessTitleSuggestion from "@/components/controls/AMeasureProcessTitleSuggestion.vue";

import { VBtn, VIcon, VChip } from "vuetify/lib";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import {
  MeasureType,
  MeasureTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";

import {
  DIALOG_ACTIONS,
  ROUTE_NAMES,
  dialogRoute,
  DIALOG_NAMES,
} from "@/routenames";

import { api as auditApi } from "@/store/modules/audit";
import { api as confApi } from "@/store/modules/configuration";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api } from "@/store/modules/measures";
import { MeasureTypeId } from "@auditcloud/shared/lib/constants";
import {
  MeasurePermissions,
  calcMeasurePermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { nullable } from "@auditcloud/shared/lib/types/common";

const confModul = namespace(confApi.namespace);
const auditModule = namespace(auditApi.namespace);
const measuresModul = namespace(api.namespace);
const userModule = namespace(userApi.namespace);

@Component({
  components: {
    ATableItem,
    VBtn,
    VIcon,
    VChip,
    AAttachmentList,
    AMeasureProcessTitleSuggestion,
  },
})
export default class AAuditOverviewMeasure extends Vue {
  loading = false;
  @Prop({
    required: true,
    type: String,
  })
  readonly measureId!: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly readonly!: boolean;

  get indent() {
    return 2;
  }

  @confModul.Getter(confApi.getters.getMeasureTypesMapping)
  measureTypesMapping!: MeasureTypeMap;

  @userModule.Getter(userApi.getters.getCurrentUserRoles)
  userRoles!: UserGetters["getCurrentUserRoles"];

  @userModule.Getter(userApi.getters.getCurrentUserRef)
  currentUser!: UserGetters["getCurrentUserRef"];

  @measuresModul.Getter(api.getters.getMappedMeasures)
  measuresMap!: { [id: string]: MeasureProcessDocument };

  @measuresModul.Action(api.actions.deleteMeasure)
  deleteMeasure!: (payload: { measureId: string }) => Promise<any>;

  @auditModule.Getter(auditApi.getters.getAuditClass)
  auditClass!: nullable<AuditClassClient>;

  get enableMeasureImplementationInQuickEditor() {
    return this.auditClass?.enableMeasureImplementationInQuickEditor;
  }

  get measure(): null | MeasureProcessDocument {
    return this.measuresMap[this.measureId] || null;
  }

  get measureType(): MeasureType {
    const measureTypeId =
      (this.measure && this.measure.additionalWorkflowMetadata.type) ??
      MeasureTypeId.Corrective;
    const measureType = this.measureTypesMapping[measureTypeId];
    if (measureType) {
      return measureType;
    } else {
      return {
        icon: "error",
        id: "xxx",
        text: "Unbekannt",
        short: "Uk",
        description: "Unbekannter Type",
        color: "red",
      };
    }
  }
  get color(): string {
    return this.measureType.color;
  }
  get icon(): string {
    return this.measureType.icon;
  }
  get type(): string {
    return this.$ft(this.measureType.text);
  }

  get measureText(): string {
    return this.measure
      ? this.measure.description
      : "ReadError - " + this.measureId;
  }
  get measureImplementation(): string {
    return this.measure
      ? this.measure.measureImplementation
      : "ReadError - " + this.measureId;
  }
  get measureSuggestion(): string {
    return this.measure
      ? this.measure.measureSuggestion
      : "ReadError - " + this.measureId;
  }
  get isDefault() {
    return (
      this.measureText ===
      this.$t(
        "components.dialogs.audit_measure_dialog.measure_created_default"
      ).toString()
    );
  }

  get permissionDelete() {
    return this.permissions.delete;
  }

  get permissions(): MeasurePermissions {
    return calcMeasurePermissions(
      this.measure,
      this.$user.id(),
      this.userRoles,
      null
    );
  }

  remove() {
    const measureId = this.measureId;
    if (
      confirm(
        this.$t("components.widgets.audit_overview_measure.confirm_delete", {
          measureId,
        }).toString()
      )
    ) {
      this.loading = true;
      this.deleteMeasure({ measureId })
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(`Delete measure ${measureId} failed`);
          this.loading = false;
          alert("Delete failed");
        });
    }
  }

  edit() {
    const newRoute = {
      name: dialogRoute(
        this.$route.name ?? ROUTE_NAMES.AUDITWRAPUP,
        DIALOG_NAMES.MEASURE
      ),
      params: {
        ...this.$route.params,
        measureId: this.measureId,
        action: DIALOG_ACTIONS.EDIT,
      },
    };
    console.log("Edit Measure", newRoute);
    this.$router.push(newRoute);
  }
}
